import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { CommonCountry, StructureSj } from "store/MasterValue/MasterValueTypes";
import {
  isArrayNullOrEmpty,
  isBacsAccountValid,
  isBacsBankValid,
  isCbnkAccountValid,
  isCbnkBankValid,
  isEftAccountValid,
  isEftBankValid,
  isFikAccountValid,
  isFikBankValid,
  isIbanValid,
  isNull,
  isNullOrEmpty
} from "../../../tools";
import PaymentCharacteristicLegalData from "./PaymentCharacteristicLegalData";
import { isValid } from "date-fns";

const DialogPaymentCharacteristic = function({
  supplierSjs,
  paymentCharacteristic,
  thirdParty,
  setPaymentCharacteristic,
  requireWorkflowPayChar,
  userInfo,
  masterValues,
  errors,
  classes
}) {
  const [localErrors, setLocalErrors] = useState([]);
  const state = useMemo(() => {
    if (!paymentCharacteristic) return;

    let isIbanEditable = false;
    let isDateEditable = false;
    let isContactEditable = false;

    if (requireWorkflowPayChar && (userInfo.canAdd || userInfo.canEdit)) {
      let supplierErtIds = [
        ...new Set(masterValues[StructureSj].filter(sj => supplierSjs.some(ssj => ssj.sj.sjCode === sj.identifier)).map(sj => sj.ertIdentifier))
      ];
      let allowedErts =
        !isNull(userInfo.structures) &&
        userInfo.structures.erts.some(ert => supplierErtIds.some(sErtId => ert.identifier === sErtId)) &&
        masterValues[CommonCountry].some(
          c =>
            c.code === thirdParty.address_CountryCode &&
            !isArrayNullOrEmpty(c.allowedPayCharTypes) &&
            c.allowedPayCharTypes.some(p => p === paymentCharacteristic.paymentCharacteristicTypeCode) &&
            !!c.allowPayCharManualEdit
        );
      let needApprobationScore50 = allowedErts && paymentCharacteristic.sisScore === 50 && userInfo.needApprobation;
      let needApprobationAnyScore = allowedErts && userInfo.needApprobation;

      if (allowedErts && !(userInfo.isAdmin || needApprobationScore50 || paymentCharacteristic.sisScore <= 70)) {
        isIbanEditable = false;
      } else if (userInfo.isAdmin || needApprobationScore50 || allowedErts || paymentCharacteristic.sisScore <= 70) {
        isIbanEditable = true;
      }
      isDateEditable = userInfo.isAdmin || needApprobationAnyScore || allowedErts;
      isContactEditable = userInfo.isAdmin || needApprobationAnyScore || allowedErts;

      return { isIbanEditable, isDateEditable, isContactEditable };
    } else
      return {
        isIbanEditable: userInfo.canAdd || userInfo.canEdit,
        isContactEditable: userInfo.canAdd || userInfo.canEdit,
        isDateEditable: userInfo.isAdmin
      };
  }, [userInfo, paymentCharacteristic]);

  useEffect(() => {
    setLocalErrors(errors);
  }, [errors]);

  // prise en compte des collectivités d'outre mer comme dans le périmètre France
  const returnCountryCode = countryCode => (["PF", "TF", "NC", "BL", "MF", "PM", "WF"].some(s => s === countryCode) ? "FR" : countryCode);

  const extractCountryFromBankAccount = bankAccount => {
    if (isNullOrEmpty(bankAccount) || bankAccount.length < 2) return null;
    let countryCode = returnCountryCode(bankAccount.substring(0, 2));
    return masterValues[CommonCountry].find(c => c.codeIso2.toUpperCase() === countryCode.toUpperCase());
  };

  const savePayChar = payChar => {
    if (payChar.cancel) setPaymentCharacteristic(payChar);

    let err = [];
    if (!payChar.startOfValidityDate || !isValid(new Date(payChar.startOfValidityDate))) {
      err.push({ code: "startOfValidityDate" });
    }
    if (payChar.endOfValidityDate === payChar.startOfValidityDate) {
      err.push({ code: "endOfValidityDate" });
    }

    var bankCountry = extractCountryFromBankAccount(payChar.bankAccount);
    if (
      isNullOrEmpty(payChar.bankAccount) ||
      (bankCountry?.bankAccountLength && payChar.bankAccount.length !== bankCountry.bankAccountLength) ||
      (payChar.paymentCharacteristicTypeCode === "IBAN" && !isIbanValid(payChar.bankAccount)) ||
      (payChar.paymentCharacteristicTypeCode === "BACS" && !isBacsAccountValid(payChar.bankAccount)) ||
      (payChar.paymentCharacteristicTypeCode === "EFT" && !isEftAccountValid(payChar.bankAccount)) ||
      (payChar.paymentCharacteristicTypeCode === "FIK" && !isFikAccountValid(payChar.bankAccount)) ||
      (payChar.paymentCharacteristicTypeCode === "CBNK" && !isCbnkAccountValid(payChar.bankAccount))
    ) {
      err.push({ code: "bankAccount", errorMessage: <Trans>Error IBAN Format</Trans> });
    }
    if (payChar.paymentCharacteristicTypeCode === "IBAN" && !isNullOrEmpty(payChar.bankCode)) {
      var INVALID_BANK_CODE_REGEX = new RegExp("[^a-zA-Z0-9]");

      if (INVALID_BANK_CODE_REGEX.test(payChar.bankCode)) {
        err.push({ code: "bankCode", errorMessage: <Trans>Error BIC contain alphaNum</Trans> });
      }
      if ((payChar.bankCode.length > 8 && payChar.bankCode.length !== 11) || (payChar.bankCode.length < 11 && payChar.bankCode.length !== 8)) {
        err.push({ code: "bankCode", errorMessage: <Trans>Error BIC Length</Trans> });
      }
      if (
        returnCountryCode(payChar.bankCode.slice(4, 6)) !==
        returnCountryCode(!!payChar.beneficiary ? payChar.beneficiary.thirdPartyCountryCode : thirdParty.countryCode)
      ) {
        err.push({ code: "bankCode", errorMessage: <Trans>Error BIC country</Trans> });
      }
    }
    if (
      (payChar.paymentCharacteristicTypeCode === "BACS" && !isBacsBankValid(payChar.bankCode)) ||
      (payChar.paymentCharacteristicTypeCode === "EFT" && !isEftBankValid(payChar.bankCode)) ||
      (payChar.paymentCharacteristicTypeCode === "FIK" && !isFikBankValid(payChar.bankCode)) ||
      (payChar.paymentCharacteristicTypeCode === "CBNK" && !isCbnkBankValid(payChar.bankCode))
    ) {
      err.push({ code: "bankCode" });
    }

    let contact = payChar.thirdPartyContact;
    if (contact) {
      if (isNullOrEmpty(contact.firstName)) {
        err.push({ code: "FIRSTNAME" });
      }
      if (isNullOrEmpty(contact.lastName)) {
        err.push({ code: "LASTNAME" });
      }
      for (var i = 0; i < contact.details.length; i++) {
        let detail = contact.details[i];
        if (!isNullOrEmpty(detail.value)) {
          if (detail.contactDetailTypeCode === "MAIL") {
            var re = /\S+@\S+\.\S+/;
            if (!re.test(detail.value)) {
              err.push({ code: "MAIL" });
            }
          } else {
            var re = /\d+/;
            if (!re.test(detail.value)) {
              err.push({ code: "PHONE" });
            }

            let rePhoneCode = /\+\d+/;
            if (!isNullOrEmpty(detail.phoneCode) && !rePhoneCode.test(detail.phoneCode)) {
              err.push({ code: "PHONE_CODE" });
            }
          }
        }
      }
    }

    if (err.length > 0) {
      setLocalErrors(err);
    } else {
      setPaymentCharacteristic(payChar);
    }
  };

  const supplierErts = [
    ...new Set(masterValues[StructureSj].filter(sj => supplierSjs.some(ssj => ssj.sj.sjCode === sj.identifier)).map(sj => sj.ertIdentifier))
  ];
  const erts = userInfo.structures.erts.filter(ert => supplierErts.includes(ert.identifier));

  if (!paymentCharacteristic) return <></>;

  return (
    <>
      <PaymentCharacteristicLegalData
        payChar={paymentCharacteristic}
        thirdParty={thirdParty}
        errors={localErrors}
        isIbanEditable={state.isIbanEditable}
        isDateEditable={state.isDateEditable}
        isContactEditable={state.isContactEditable}
        savePaymentCharacteristic={savePayChar}
        classes={classes}
        erts={erts}
        requireWorkflowPayChar={requireWorkflowPayChar}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    userInfo: {
      isAdmin: HasRight("application.admin"),
      canAdd: HasRight("thirdparty_supplier.add_iban"),
      canEdit: HasRight("thirdparty_supplier.edit_iban"),
      structures: state.AuthenticationReducer.user.structureTps
    }
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(DialogPaymentCharacteristic));
